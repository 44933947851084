// 酒店订单状态(对应webk中HOTEL_ORDER_STATUS)
export const HOTEL_ORDER_STATUS = {
  TO_BE_PAID: 1, // 待支付
  TO_BE_CONFIRMED: 2, // 待确认
  TO_BE_LIVE: 3, // 待入住
  LIVED: 4, // 已入住
  FINISHED: 5, // 已完成
  CLOSED: 6, // 已关闭
  REFUNDING: 7, // 退款中
  REFUNDED: 8 // 已退款
}

// 酒店订单状态文案
export const HOTEL_ORDER_STATUS_TEXT = {
  [HOTEL_ORDER_STATUS.TO_BE_PAID]: '待支付',
  [HOTEL_ORDER_STATUS.TO_BE_CONFIRMED]: '待确认',
  [HOTEL_ORDER_STATUS.TO_BE_LIVE]: '待入住',
  [HOTEL_ORDER_STATUS.LIVED]: '已入住',
  [HOTEL_ORDER_STATUS.FINISHED]: '已完成',
  [HOTEL_ORDER_STATUS.CLOSED]: '已关闭',
  [HOTEL_ORDER_STATUS.REFUNDING]: '退款中',
  [HOTEL_ORDER_STATUS.REFUNDED]: '已退款'
}

// 团房申请单状态
export const GROUP_ROOM_ORDER_STATUS = {
  NEW_INQUIRY: 0, // 新询单
  WAIT_INQUIRY: 1, // 待询价
  INQUIRED: 2, // 已询价
  QUOTED: 3, // 已报价
  CONFIRM_QUOTATION: 4, // 确认报价
  PENDING_TRIAL: 5, // 待审预订单
  PREDETERMINE: 6, // 预订单
  CANCELED: 7, // 已取消
}
// 团房申请单状态文案
export const GROUP_ROOM_ORDER_STATUS_TEXT = {
  [GROUP_ROOM_ORDER_STATUS.NEW_INQUIRY]: '新询单',
  [GROUP_ROOM_ORDER_STATUS.WAIT_INQUIRY]: '待询价',
  [GROUP_ROOM_ORDER_STATUS.INQUIRED]: '已询价',
  [GROUP_ROOM_ORDER_STATUS.QUOTED]: '已报价',
  [GROUP_ROOM_ORDER_STATUS.CONFIRM_QUOTATION]: '确认报价',
  [GROUP_ROOM_ORDER_STATUS.PENDING_TRIAL]: '待审预订单',
  [GROUP_ROOM_ORDER_STATUS.PREDETERMINE]: '预订单',
  [GROUP_ROOM_ORDER_STATUS.CANCELED]: '已取消',
}
