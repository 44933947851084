<template>
  <van-form colon :label-width="110" :class="styles.index">
    <van-field label="分销商">
      <template slot="input">{{ detail.agencyName }}</template>
    </van-field>
    <van-field label="联系电话">
      <template slot="input">{{ detail.contactPhone }}</template>
    </van-field>
    <van-field label="酒店地区">
      <template slot="input">{{ area }}</template>
    </van-field>
    <van-field label="指定酒店">
      <template slot="input">{{ detail.hotelName }}</template>
    </van-field>
    <van-field label="入离日期">
      <template slot="input">{{ dateRange }}</template>
    </van-field>
    <van-field label="间夜单价预算">
      <template slot="input">{{ budget }}</template>
    </van-field>
    <van-field label="团房成人数">
      <template slot="input">{{ detail.adultNum || 0 }}人</template>
    </van-field>
    <van-field label="团房儿童数">
      <template slot="input">{{ detail.childNum || 0 }}人</template>
    </van-field>
    <van-field label="双床房数量">
      <template slot="input">{{ detail.doubleBedNum }}</template>
    </van-field>
    <van-field label="大床房数量">
      <template slot="input">{{ detail.queenBedNum }}</template>
    </van-field>
    <van-field label="是否含早">
      <template slot="input">{{ detail.includeBreakfast ? '含早': '不含早' }}</template>
    </van-field>
    <van-field label="客人国籍">
      <template slot="input">{{ nationalText }}</template>
    </van-field>
    <van-field label="其他需求">
      <template slot="input">
        {{ detail.otherRequirements }}
      </template>
    </van-field>
    <van-field label="状态">
      <template slot="input">
        {{ GROUP_ROOM_ORDER_STATUS_TEXT[detail.status] }}
      </template>
    </van-field>
  </van-form>
</template>

<script>
import dayjs from 'dayjs'
import {
  GROUP_ROOM_ORDER_STATUS,
  GROUP_ROOM_ORDER_STATUS_TEXT
} from '@/constants/order'
import mixinLoading from '@/mixin/loading'
import {
  getGroupBookingApplicationOrder,
  getGroupBookingApplicationOrderForSaaS,
  rejectGroupBookingApplicationOrder
} from '@/api/hotel'
import { loading } from '@/tool/loading'
import { isEmpty } from '@/tool/common'
import styles from './index.module.scss'

export default {
  name: 'GroupHotel',
  mixins: [mixinLoading],
  props: {
    // 是否用于 SaaS 系统访问
    saas: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      styles,
      GROUP_ROOM_ORDER_STATUS,
      GROUP_ROOM_ORDER_STATUS_TEXT,
      detail: {}
    }
  },
  computed: {
    // 酒店地区
    area() {
      const { countryName, provinceName, cityName } = this.detail

      return countryName || provinceName || cityName ? [countryName, provinceName, cityName].join('-') : undefined
    },
    // 间夜单价预算
    budget() {
      const { unitPrice, currencyCode } = this.detail
      return isEmpty(unitPrice) ? undefined : `${unitPrice} ${currencyCode}`
    },
    // 入离日期
    dateRange() {
      const { checkInDate, checkOutDate } = this.detail

      if (!checkInDate || !checkOutDate) return undefined

      const dStart = dayjs(checkInDate)
      const dEnd = dayjs(checkOutDate)

      const start = dStart.format('YYYY-MM-DD')
      const end = dEnd.format('YYYY-MM-DD')
      const diff = dEnd.diff(dStart, 'd')

      return `${start}到${end}（${diff}晚）`
    },
    // 国籍文案
    nationalText() {
      const { nationalities = [] } = this.detail
      return nationalities.map(e => e.zhName).join(',')
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const { id, tenantId } = this.$route.query

      this.detail = await loading(
        this.saas
          ? getGroupBookingApplicationOrderForSaaS({ id, tenantId })
          : getGroupBookingApplicationOrder(id)
      )
    },
    async handleReject() {
      try {
        await this.$dialog.confirm({
          message: '确认拒单？'
        })
        await this.handleLoading(rejectGroupBookingApplicationOrder(this.detail.id))
        this.$toast('拒单成功')
        this.init()
      } catch (err) {
        /** */
      }
    },
    handleReply() {
      const { id } = this.$route.query
      this.$router.push({
        name: 'groupHotelReply',
        query: { id, agencyId: this.detail.agencyId }
      })
    }
  }
}
</script>
